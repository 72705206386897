import { Divider, Layout, Modal } from 'antd';
import { FC, ReactNode } from 'react';
import { styled } from 'styled-components';
import { Header } from './Header';
import { useWindowWidth } from '@react-hook/window-size';
import { screenSizes } from '../../styles';
import { Waitlist } from '../Waitlist';
import { Footer } from './Footer';
import { ApiResponse } from '../../../pages';

const StyledLayout = styled(Layout)`
  background: #fff;
`;
export interface SiteLayoutProps {
  children: ReactNode;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  addToWaitlist: (name: string, email: string) => Promise<ApiResponse>;
}
export const SiteLayout: FC<SiteLayoutProps> = ({
  openModal,
  setOpenModal,
  children,
  addToWaitlist,
}) => {
  const width = useWindowWidth();
  const hide = { style: { display: 'none' } };
  return (
    <StyledLayout>
      <Header
        {...{
          onJoinWaitlist() {
            setOpenModal(true);
          },
        }}
      />
      {width < screenSizes.laptop && openModal ? (
        <Waitlist
          {...{
            async onJoinWaitlist(data) {
              return addToWaitlist(data.name, data.email);
            },
            onBack() {
              setOpenModal(false);
            },
          }}
        />
      ) : (
        children
      )}
      <Divider />
      <Footer />
      {width >= screenSizes.laptop && (
        <Modal
          open={openModal}
          onCancel={() => setOpenModal(false)}
          okButtonProps={hide}
          cancelButtonProps={hide}
        >
          <Waitlist
            {...{
              async onJoinWaitlist(data) {
                return addToWaitlist(data.name, data.email);
              },
              onBack() {
                setOpenModal(false);
              },
            }}
          />
        </Modal>
      )}
    </StyledLayout>
  );
};
