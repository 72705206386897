import { FC } from 'react';
import { Hero } from './Hero';
import { Reputation } from './Ruputation';
import { Messenger } from './Messenger';
import { Community } from './Community';
import { Creativity } from './Creativity';
import { Partners } from './Partners';
import { StyledContent } from '../../common';

export interface LandingPageProps {
  onJoinWaitlist: () => void;
}
export const LandingPage: FC<LandingPageProps> = ({ onJoinWaitlist }) => {
  return (
    <StyledContent>
      <Hero {...{ onJoinWaitlist }} />
      <Partners />
      <Reputation />
      <Messenger />
      <Community />
      <Creativity {...{ onJoinWaitlist }} />
    </StyledContent>
  );
};
