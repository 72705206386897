import { FC, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { animation, devices } from '../../../../common';

const Root = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Dot = styled.div<{ $isvisible: 'true' | 'false' }>`
  @media ${devices.mobileS} {
    padding-bottom: 6px;
  }
  @media ${devices.laptop} {
    padding-bottom: 36px;
  }
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: ${({ $isvisible }) => ($isvisible === 'true' ? 'flex' : 'none')};
  animation:
    ${animation.fadeIn} 1s ease-in-out,
    ${animation.bounce} 0.5s ease-in-out;
`;
const DotText = styled.div`
  font-family: Satoshi;
  font-style: normal;
  font-weight: 700;
  @media ${devices.mobileS} {
    font-size: 36.253px;
    line-height: 39.275px; /* 108.333% */
  }
  @media ${devices.laptop} {
    font-size: 96px;
    line-height: 104px; /* 108.333% */
  }

  background: var(
    --Primary-Main,
    linear-gradient(180deg, #ed5372 0%, #bd2846 100%, #e33659 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const Text = styled.div<{ $isvisible: 'true' | 'false' }>`
  color: var(--Gray-700, #374151);
  font-family: Satoshi;
  font-style: normal;
  font-weight: 700;
  @media ${devices.mobileS} {
    font-size: 32px;
    line-height: 40px; /* 110% */
  }
  @media ${devices.laptop} {
    font-size: 50px;
    line-height: 88px; /* 110% */
  }

  display: ${({ $isvisible }) => ($isvisible === 'true' ? 'flex' : 'none')};
  animation:
    ${animation.fadeIn} 0.5s ease-in-out,
    ${animation.bounce} 0.1s ease-in-out;
`;
export interface HeadlineProps {}
export const Headline: FC<HeadlineProps> = ({}) => {
  const headline = 'Connect.Create.Earn';
  const [visibility, setVisibility] = useState<boolean[]>(
    Array(headline.length).fill(false),
  );
  useEffect(() => {
    let localVisibility = visibility;
    let index = 0;
    let interval: NodeJS.Timer;
    interval = setInterval(() => {
      if (index === headline.length) {
        // localVisibility = Array(headline.length).fill(false);
        // setVisibility([...localVisibility]);
        // index = 0;
        clearInterval(interval);
      } else {
        localVisibility[index] = true;
        setVisibility([...localVisibility]);
        index++;
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);
  return (
    <Root>
      {headline.split('').map((item, index) => {
        const key = `headline-string-${index}`;
        const $isvisible = visibility[index] ? 'true' : 'false';
        return item === '.' ? (
          <Dot key={key} $isvisible={$isvisible}>
            <DotText>{item}</DotText>
          </Dot>
        ) : (
          <Text key={key} $isvisible={$isvisible}>
            {item}
          </Text>
        );
      })}
    </Root>
  );
};
