import { FC, ReactNode, memo, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { animation } from "../../styles";

const Root = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  .animate {
    animation:
      ${animation.slideUp} 1s ease-in-out,
      ${animation.fadeIn} 1s ease-in-out;
  }
`;

const Box = styled.div`
  padding: 0;
`;
export interface SpinnerProps {
  node?: ReactNode;
}
export const Spinner: FC<SpinnerProps> = memo(({ node }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.classList.add("animate");
    setTimeout(() => {
      ref.current?.classList.remove("animate");
    }, 1000);
  }, [node]);
  return (
    <Root>
      <Box ref={ref}>{node}</Box>
    </Root>
  );
});
