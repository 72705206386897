import { FC, useState } from 'react';
import { styled } from 'styled-components';
import { ApiResponse, IArticle } from '../../types';
import {
  DotIcon,
  LandingBtn,
  LogoFullIcon,
  StyledContent,
} from '../../../common';
import { BlogCard, BlogCardProps } from '../BlogCard';
import { Col, Modal, Row } from 'antd';
import { SubscribeBlog } from '../../../common/base';
import { cssHide } from '../../../utils';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  margin-top: 20px;
  max-width: 920px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const Title = styled.div`
  color: var(--Gray-700, #374151);
  text-align: center;
  font-family: Satoshi;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 133.333% */
`;
const Banner = styled.img`
  width: 100%;
  height: 426px;
  flex-shrink: 0;
`;
const ToolBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ToolBoxMiddleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
`;
const ToolBoxMiddleContent = styled.div`
  color: var(--Gray-700, #374151);
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  svg {
    width: 4px;
    height: 4px;
    circle {
      fill: #7f8a9a;
    }
  }
`;
const ArticleBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const ArticleContentTitle = styled.div`
  width: 100%;
  color: var(--Gray-700, #374151);
  font-family: Satoshi;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;
const ArticleContent = styled.div`
  color: var(--Gray-700, #374151);
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
  width: 100%;
`;
const ContentArrayContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;
`;
const RelatedTitle = styled.div`
  color: var(--Gray-700, #374151);
  font-family: Satoshi;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
  width: 100%;
  margin-top: 50px;
`;
export interface BlogViewProps {
  article?: IArticle;
  related: IArticle[];
  onOpen: (blogId: string) => void;
  onSub: (email: string) => Promise<ApiResponse>;
}
export const BlogView: FC<BlogViewProps> = ({
  article,
  related,
  onOpen,
  onSub,
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <Root>
      <StyledContent>
        <Content>
          <Title dangerouslySetInnerHTML={{ __html: article?.title || '' }} />
          <Banner
            src={article?.image || '/persona-bg.svg'}
            alt={article?.title}
          />
          <ToolBox>
            <LogoFullIcon style={{ width: 126, height: 27 }} />
            <ToolBoxMiddleContainer>
              <ToolBoxMiddleContent>
                <span>Published {article?.publishedDate} </span>
                <DotIcon /> <span>{article?.readTimeInMinute} mins read</span>
              </ToolBoxMiddleContent>
            </ToolBoxMiddleContainer>
            <LandingBtn
              onClick={() => setOpenModal(true)}
              style={{ width: 110, padding: '16px 24px', fontSize: 16 }}
            >
              Subscribe
            </LandingBtn>
          </ToolBox>
          {article?.htmlBody?.map((artBody, index) => {
            return (
              <ArticleBody key={`article-details-body-${index}`}>
                {artBody.header && (
                  <ArticleContentTitle style={{ marginBottom: 10 }}>
                    {artBody.header}
                  </ArticleContentTitle>
                )}
                {typeof artBody.content === 'string' ? (
                  <ArticleContent
                    dangerouslySetInnerHTML={{ __html: artBody.content }}
                  />
                ) : (
                  <ContentArrayContainer>
                    {artBody.content.map((item, index) => (
                      <ArticleContent
                        key={`article-content-array-${index}`}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    ))}
                  </ContentArrayContainer>
                )}
              </ArticleBody>
            );
          })}
          {related.length > 0 && (
            <>
              <RelatedTitle>You might like these articles:</RelatedTitle>
              <Row gutter={[24, 24]}>
                {related.map((article, index) => {
                  return (
                    <Col xs={24} md={12} key={`related-article-${index}`}>
                      <BlogCard
                        {...{ article }}
                        onOpen={() => onOpen(article.id)}
                      />
                    </Col>
                  );
                })}
              </Row>
            </>
          )}
        </Content>
        <Modal
          open={openModal}
          onCancel={() => setOpenModal(false)}
          okButtonProps={cssHide}
          cancelButtonProps={cssHide}
        >
          <SubscribeBlog
            {...{
              onSub,
            }}
          />
        </Modal>
      </StyledContent>
    </Root>
  );
};
