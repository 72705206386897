import { useEffect, useState } from 'react';
import { Blogs, IArticle, routeNames } from '../../../ui';
import { useNavigate } from 'react-router-dom';
import { blogApi, onSub } from '../../utils';

export default function BlogsPage() {
  const [articles, setArticles] = useState<IArticle[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${blogApi}/metaData`)
      .then((res) => res.json())
      .then((res) => {
        setArticles(res);
      })
      .catch((e) => console.error({ e }));
  }, []);

  return (
    <Blogs
      {...{
        articles,
        onOpen(index) {
          navigate(routeNames.blog_ + index);
        },
        onSub,
      }}
    />
  );
}
