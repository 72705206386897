import { FC, useState } from 'react';
import { styled } from 'styled-components';
import { IArticle } from '../types';
import {
  Description,
  Headline,
  HeadlineText,
  LandingBtn,
  LandingPageField,
  StyledContent,
} from '../../common';
import { Col, Modal, Row } from 'antd';
import { BlogCard } from './BlogCard';
import { SubscribeBlog, SubscribeBlogProps } from '../../common/base';
import { cssHide } from '../../utils';
const BlogContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;
const SubBtnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
export interface BlogsProps {
  articles: IArticle[];
  onOpen: (id: string) => void;
  onSub: SubscribeBlogProps['onSub'];
}
export const Blogs: FC<BlogsProps> = ({ articles, onOpen, onSub }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <StyledContent>
      <LandingPageField>
        <Headline>
          <HeadlineText>Read</HeadlineText>
          <HeadlineText $colored="true">Our</HeadlineText>
          <HeadlineText>Minds</HeadlineText>
        </Headline>
        <Description style={{ maxWidth: 900 }}>
          Explore our blog for articles on Web3, decentralized identities, ENS,
          and more. Stay informed about blockchain, digital sovereignty, and the
          future of the internet!
        </Description>
        <SubBtnContainer>
          <LandingBtn onClick={() => setOpenModal(true)} style={{ width: 300 }}>
            Subscribe to updates
          </LandingBtn>
        </SubBtnContainer>
        <BlogContainer>
          <Row gutter={[12, 12]} style={{ width: '100%' }}>
            {articles?.map((article, index) => {
              return (
                <Col
                  xs={24}
                  md={12}
                  lg={8}
                  key={`article-box-${index}`}
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <BlogCard
                    {...{ article }}
                    onOpen={() => onOpen(article.id)}
                  />
                </Col>
              );
            })}
          </Row>
        </BlogContainer>
        <Modal
          open={openModal}
          onCancel={() => setOpenModal(false)}
          okButtonProps={cssHide}
          cancelButtonProps={cssHide}
        >
          <SubscribeBlog
            {...{
              onSub,
            }}
          />
        </Modal>
      </LandingPageField>
    </StyledContent>
  );
};
