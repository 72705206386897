export const personaInfo = {
  telegram: '+2Wq4FY5YRT1iN2Ix', //"https://t.me/+2Wq4FY5YRT1iN2Ix",
  twitter: 'personadotart', //"https://twitter.com/personadotart",
  discord: 'JCfFUx6p', //"https://discord.gg/JCfFUx6p",
};
export const personaInfoDetail = {
  telegram: 'https://t.me/+2Wq4FY5YRT1iN2Ix',
  twitter: 'https://twitter.com/personadotart',
  discord: 'https://discord.gg/JCfFUx6p',
  mirror: 'https://mirror.xyz/persona.art',
  ens: 'https://app.ens.domains/persona.art',
  farcaster: 'https://warpcast.com/personadotart',
};
