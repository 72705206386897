import { FC } from 'react';
import { styled } from 'styled-components';
import { Divider } from 'antd';
import { LogoFullIcon, LoveIcon } from '../../..';

const Root = styled.div`
  display: flex;
  justify-content: center;
  background: #fff;
`;
const Box = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  color: var(--Gray-500, #6b7280);
  text-align: center;
  font-family: Satoshi-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 233.333% */
`;
export interface FooterProps {}
export const Footer: FC<FooterProps> = () => {
  return (
    <Root>
      <Box>
        <LogoFullIcon style={{ height: 50, width: 200 }} />
        <Divider>
          <Label>
            Designed in New York City <LoveIcon />
          </Label>
        </Divider>
      </Box>
    </Root>
  );
};
