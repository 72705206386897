import { routeNames } from '../../ui';
import HomePage from '../pages/landing';
import BlogsPage from '../pages/blogs';
import BlogArticle from '../pages/blog';

// const LandingPage = React.lazy(() => import('../pages/landing/index'));
// const Blogs = React.lazy(() => import('../pages/blogs/index'));
// const BlogView = React.lazy(() => import('../pages/blog/index'));

export const appRoutes = [
  { path: routeNames.home, element: HomePage },
  { path: routeNames.blogs, element: BlogsPage },
  { path: routeNames.blog, element: BlogArticle },
];
