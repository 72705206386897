import { configureStore } from "@reduxjs/toolkit";
import settingsState from "./settings.state";
export const store = configureStore({
  reducer: {
    settings: settingsState,
  }
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store.getState;
export default store;
