import { FC } from 'react';
import { styled } from 'styled-components';
import { DropupIcon } from '../..';
import { Spinner } from '../Spnner';

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
`;
const IconContainer = styled.div<{ $level: ILevel }>`
  display: flex;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6.667px;
  border-radius: 66.667px;
  ${({ $level }) => {
    switch ($level) {
      case 'medium':
        return `
        background: var(--Yellow-100, #713F12);
        svg path {
          stroke: #FEF08A;
        }
      `;
      case 'high':
        return `
        background: #14532D;
        svg path {
          stroke: #BBF7D0;
        }
        `;
      default:
        return `
          
          background: var(--Red-100, #7F1D1D);
        svg path {
          stroke: #fee2e2;
        }        
      `;
    }
  }}
`;
const Score = styled.div`
  display: flex;
  gap: 1px;
`;
export type ILevel = 'low' | 'medium' | 'high';
export interface RepScoreProps {
  score: number;
}
export const RepScore: FC<RepScoreProps> = ({ score }) => {
  const level: ILevel = score < 35 ? 'low' : score < 65 ? 'medium' : 'high';
  return (
    <Root>
      <Score>
        {String(Number(score / 10).toFixed(1))
          .split('')
          .map((item, index) => {
            return (
              <Spinner node={item.toString()} key={`item-spinner-${index}`} />
            );
          })}
      </Score>
      <Spinner
        key={`item-spinner-${String(score).length}`}
        node={
          <IconContainer $level={level}>
            <DropupIcon />
          </IconContainer>
        }
      />
    </Root>
  );
};
