import { DefaultTheme } from "styled-components";
export const screenSizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2660,
};
export const devices = {
  mobileS: `(min-width: ${screenSizes.mobileS}px)`,
  mobileM: `(min-width: ${screenSizes.mobileM}px)`,
  mobileL: `(min-width: ${screenSizes.mobileL}px)`,
  tablet: `(min-width: ${screenSizes.tablet}px)`,
  laptop: `(min-width: ${screenSizes.laptop}px)`,
  laptopL: `(min-width: ${screenSizes.laptopL}px)`,
  desktop: `(min-width: ${screenSizes.desktop}px)`,
};

export const appLayoutHeights = {
  header: 60,
};
export const badgeColors = [
  "#103DDB",
  "#7315BD",
  "#DB1041",
  "#CA4A02",
  "#0F81B1",
  "#603939",
];
export const getColor = (
  { theme: { colors } }: any,
  colorName: keyof IColorScheme
) => (colors as IColorScheme)?.[colorName];

const colors1 = {
  primarySingle: "#166534",
  primaryBg: "#F6FFF9",
  primary: "linear-gradient(180deg, #ED5372 0%, #BD2846 100%, #E33659 100%)",
  primaryLight: "#FFF4F7",
  primaryHighlight: "#ED5372",
  accent: "#000",
};
export interface IColorScheme {
  background: string;
  bgSecondary: string;
  boxBg: string;
  textColor: string;
  highlight: string;
  textSecondary: string;
  textTertiary: string;
  borderLine: string;
  primary: string;
  primarySingle: string;
  primaryBg: string;
  primaryHighlight: string;
  bgPrimary: string;
  secondary: string;
  accent: string;
  success: string;
  fail: string;
  colorBgContainer: string;
  logoColor: string;
}

export interface AppTheme extends DefaultTheme {
  colors: IColorScheme;
}
