import styled, { keyframes } from 'styled-components';
import { devices } from './theme';
import { Layout } from 'antd';

export const animation = {
  flight: keyframes`
    0% {
      transform: translate(-100%, 100%);
    }
    25% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(100%, -100%);
    }
    75% {
      transform: translate(-100%, 100%);
    }
    100% {
      transform: translate(0, 0);
    }
  `,
  clockwise360: keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `,
  merge: {
    merge6: keyframes`
      0% {
        transform: translate(300px, -200px) rotate(${15}deg);
      }
      to {
        transform: translate(0px, 0px) rotate(0deg);
      }
    `,
    merge5: keyframes`
      0% {
        transform: translateX(300px);
      }
      to {
        transform: translateX(0px);
      }
    `,
    merge4: keyframes`
      0% {
        transform: translate(300px, 200px) rotate(-${15}deg);
      }
      to {
        transform: translate(0px, 0px) rotate(0deg);
      }
    `,
    merge3: keyframes`
      0% {
        transform: translate(-300px, 200px) rotate(${15}deg);
      }
      to {
        transform: translate(0px, 0px) rotate(0deg);
      }
    `,
    merge2: keyframes`
      0% {
        transform: translateX(-300px);
      }
      to {
        transform: translateX(0px);
      }
    `,
    merge1: keyframes`
      0% {
        transform: translate(-300px, -200px) rotate(-${15}deg);
      }
      to {
        transform: translate(0px, 0px) rotate(0deg);
      }
    `,
  },
  scatter: {
    move6: keyframes`
      0% {
        transform: translate(0px, 0px) rotate(0deg);
      }
      to {
        transform: translate(300px, -200px) rotate(${15}deg);
      }
    `,
    move5: keyframes`
      0% {
        transform: translateX(0px);
      }
      to {
        transform: translateX(300px);
      }
    `,
    move4: keyframes`
      0% {
        transform: translate(0px, 0px) rotate(0deg);
      }
      to {
        transform: translate(300px, 200px) rotate(-${15}deg);
      }
    `,
    move3: keyframes`
      0% {
        transform: translate(0px, 0px) rotate(0deg);
      }
      to {
        transform: translate(-300px, 200px) rotate(${15}deg);
      }
    `,
    move2: keyframes`
      0% {
        transform: translateX(0px);
      }
      to {
        transform: translateX(-300px);
      }
    `,
    move1: keyframes`
      0% {
        transform: translate(0px, 0px) rotate(0deg);
      }
      to {
        transform: translate(-300px, -200px) rotate(-${15}deg);
      }
    `,
  },
  zoomIn: keyframes`
    from {
      transform: scale(.5)
    }
    to {
      transform: scale(1)
    }
  `,
  zoomOut: keyframes`
    from {
      transform: scale(1)
    }
    to {
      transform: scale(.5)
    }
  `,
  slideUp: keyframes`
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  `,
  fadeIn: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
  bounce: keyframes`
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0px);
    }
  `,
};
export const StyledContent = styled(Layout.Content)`
  margin-top: 100px;
  min-height: calc(100dvh - 100px);
  padding: 20px;
  background: #fff;
  overflow: auto;
  animation: ${animation.fadeIn} 0.5s ease-out;
`;
export const Headline = styled.div`
  display: inline-flex;
  align-items: flex-start;
`;
export const HeadlineText = styled.div<{ $colored?: 'true' | 'false' }>`
  color: var(--Gray-700, #374151);
  font-family: Satoshi;

  @media ${devices.mobileS} {
    font-family: Satoshi;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
  }
  @media ${devices.laptop} {
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 104px; /* 108.333% */
  }
  ${({ $colored }) =>
    $colored === 'true' &&
    `
    background: var(--Primary-Main, linear-gradient(180deg, #ED5372 0%, #BD2846 100%, #E33659 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}
`;
export const Description = styled.div`
  font-family: Satoshi-Medium;
  max-width: 650px;
  color: var(--Gray-500, #6b7280);
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  @media ${devices.laptop} {
    line-height: 28px; /* 140% */
    font-size: 20px;
  }
  @media ${devices.mobileS} {
    font-size: 16px;
    max-width: 1000px;
    line-height: 24px; /* 142.857% */
  }
`;
export const LandingPageField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  padding-bottom: 50px;
`;
export const Label = styled.div`
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 0.5px solid rgba(75, 85, 99, 0);
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(75, 85, 99, 0.04);

  color: var(--Gray-600, #4b5563);
  font-family: Satoshi-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;
export const LogoText = styled.div`
  font-family: Satoshi;
  font-size: 29.779px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  background: var(
    --Primary-Main,
    linear-gradient(180deg, #ed5372 0%, #bd2846 100%, #e33659 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const Logo = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 2px solid var(--Primary-Main, #ed5372);
  background: url('/logo.png') no-repeat center center / cover;
`;

export const LandingBtn = styled.div`
  cursor: pointer;
  display: flex;
  width: 194px;
  padding: 16px 24px 16px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: var(--Background-Dark-200, #0b1323);

  color: #fff;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
`;
