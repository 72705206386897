import { FC, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import {
  Description,
  DotIcon,
  Headline,
  HeadlineText,
  Label,
  LandingPageField,
  animation,
  devices,
} from '../../../common';

const CardContainer = styled.div`
  @media ${devices.mobileS} {
    display: none;
  }
  @media ${devices.laptop} {
    display: flex;
  }
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 600px;
  .zoomOut {
    animation: ${animation.zoomOut} 1s ease-out;
  }
  .zoomIn {
    animation: ${animation.zoomIn} 1s ease-out;
  }
  .zoomOutState {
    width: ${549.681 * 0.5}px;
    height: ${431.216 * 0.5}px;
  }
  .zoomOutStateOthers {
    width: ${549.681 * 0.4}px;
    height: ${431.216 * 0.4}px;
  }
  .angle1state {
  }
  .angle2state {
  }
  .zoomInState {
    width: 549.681px;
    height: 431.216px;
  }
  .move1out {
    animation: ${animation.scatter.move1} 1s ease-out;
  }
  .merge1out {
    animation: ${animation.merge.merge1} 1s ease-out;
  }
  .pos1state {
    left: -415px;
    top: 0px;
    transform: rotate(-${15}deg);
  }
  .merge2out {
    animation: ${animation.merge.merge2} 1s ease-out;
  }
  .move2out {
    animation: ${animation.scatter.move2} 1s ease-out;
  }
  .pos2state {
    left: -415px;
  }
  .merge3out {
    animation: ${animation.merge.merge3} 1s ease-out;
  }
  .move3out {
    animation: ${animation.scatter.move3} 1s ease-out;
  }
  .pos3state {
    left: -415px;
    top: 430px;
    transform: rotate(${15}deg);
  }
  .merge4out {
    animation: ${animation.merge.merge4} 1s ease-out;
  }
  .move4out {
    animation: ${animation.scatter.move4} 1s ease-out;
  }
  .pos4state {
    right: -415px;
    top: 430px;
    transform: rotate(-${15}deg);
  }
  .merge5out {
    animation: ${animation.merge.merge5} 1s ease-out;
  }
  .move5out {
    animation: ${animation.scatter.move5} 1s ease-out;
  }
  .pos5state {
    right: -415px;
  }
  .merge6out {
    animation: ${animation.merge.merge6} 1s ease-out;
  }
  .move6out {
    animation: ${animation.scatter.move6} 1s ease-out;
  }
  .pos6state {
    right: -415px;
    top: 0px;
    transform: rotate(${15}deg);
  }
`;
const Card = styled.div<{ $index: number; $image: string }>`
  width: 549.681px;
  height: 431.216px;
  border-radius: 5.996px;
  background: lightgray;

  position: absolute;
  background: url('${({ $image }) => $image}') no-repeat center center / cover;
  ${({ $index }) => ($index === 0 ? `z-index: 100` : `z-index: 0`)};
`;
const ImageContainer = styled.div`
  @media ${devices.mobileS} {
    display: flex;
  }
  @media ${devices.laptop} {
    display: none;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
export interface CommunityProps {}
export const Community: FC<CommunityProps> = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cardRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  useEffect(() => {
    setTimeout(() => {
      const head = cardRefs[0].current;
      const head1 = cardRefs[1].current;
      const head2 = cardRefs[2].current;
      const head3 = cardRefs[3].current;
      const head4 = cardRefs[4].current;
      const head45 = cardRefs[5].current;
      const head6 = cardRefs[6].current;

      setTimeout(() => {}, 1000);
      containerRef.current?.addEventListener('mouseenter', (e) => {
        head?.classList.add('zoomOut');
        head1?.classList.add('move1out');
        head2?.classList.add('move2out');
        head3?.classList.add('move3out');
        head4?.classList.add('move4out');
        head45?.classList.add('move5out');
        head6?.classList.add('move6out');
        setTimeout(() => {
          head?.classList.replace('zoomOut', 'zoomOutState');
          head1?.classList.replace('move1out', 'pos1state');
          head2?.classList.replace('move2out', 'pos2state');
          head3?.classList.replace('move3out', 'pos3state');
          head4?.classList.replace('move4out', 'pos4state');
          head45?.classList.replace('move5out', 'pos5state');
          head6?.classList.replace('move6out', 'pos6state');
        }, 1000);
      });
      containerRef.current?.addEventListener('mouseleave', (e) => {
        head?.classList.replace('zoomOutState', 'zoomIn');
        head1?.classList.replace('pos1state', 'merge1out');
        head2?.classList.replace('pos2state', 'merge2out');
        head3?.classList.replace('pos3state', 'merge3out');
        head4?.classList.replace('pos4state', 'merge4out');
        head45?.classList.replace('pos5state', 'merge5out');
        head6?.classList.replace('pos6state', 'merge6out');
        setTimeout(() => {
          head?.classList.remove('zoomIn');
          head1?.classList.remove('merge1out');
          head2?.classList.remove('merge2out');
          head3?.classList.remove('merge3out');
          head4?.classList.remove('merge4out');
          head45?.classList.remove('merge5out');
          head6?.classList.remove('merge6out');
        }, 1000);
      });
    }, 2000);
  }, []);
  return (
    <LandingPageField ref={containerRef}>
      <Label>
        <DotIcon /> Do creative work
      </Label>
      <Headline>
        <HeadlineText>Join</HeadlineText>
        <HeadlineText $colored="true">The</HeadlineText>
        <HeadlineText>Community</HeadlineText>
      </Headline>
      <Description>
        Embrace community in Web3: Join your favorite communities, earn tokens
        through proposals, craft custom content, and gain voting and chat access
        in dedicated community channels.
      </Description>
      <CardContainer>
        {Array(7)
          .fill('')
          .map((_, index) => {
            return (
              <Card
                $index={index}
                $image={`/com${index}.png`}
                ref={cardRefs[index]}
                key={`card-item-${index}`}
                className={
                  index !== 0 ? `zoomOutStateOthers angle${index}state` : ''
                }
              />
            );
          })}
      </CardContainer>
      <ImageContainer>
        <Image src="/communities.png" alt="persona communities" />
      </ImageContainer>
    </LandingPageField>
  );
};
