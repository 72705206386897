import { ApiResponse } from "../../ui";

export const onSub = async (email: string): Promise<ApiResponse> => {
  try {
    const awaitResponse = await fetch(
      `${process.env.REACT_APP_BACK_END_URL}/sub/blog`,
      {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const { data, error } = await awaitResponse.json();
    if (data) return data;
    else throw error;
  } catch (err) {
    return { err: err as Error };
  }
};
