import { personaInfo } from '../../../../common';
import { IPersona } from '../../../types';

export const ensNames = ['adebimpe.xyz', 'brantly.eth', 'karyagar.eth'];
const guilds = ['creator', 'engineer', 'artist'];
export const people: IPersona[] = ensNames.map((item, index) => ({
  address: '0x',
  ens: item,
  score: 30 * (index + 1),
  texts: {
    'art.persona': guilds[index],
    avatar: `/p${index}.png`,
    description: 'Connect.Create.Earn',
    'com.twitter': personaInfo.twitter, //"@chokey2nv",
    'org.telegram': personaInfo.telegram, // "@ceejay_priest",
    'com.discord': personaInfo.discord, // "@ceejay_priest",
  },
  nfts: {
    avatar: {
      block_number: 0,
      tx_hash: '0x',
      contract: '0x',
      standard: 'erc1155',
      token_id: '1',
      url: '',
      resolved: {
        image: `/${index}.png`,
        name: '',
        description: '',
      },
    },
    banner: undefined,
  },
}));
