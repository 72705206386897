import { Button, Input } from 'antd';
import { FC, useState } from 'react';
import { styled } from 'styled-components';
import { BackArrowIcon, EmailIcon, UserIcon, devices } from '../../../common';

const Root = styled.div`
  display: inline-flex;
  padding: 32px 32px 48px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  background: #fff;
  border-radius: 24px;

  @media ${devices.mobileS} {
    width: 100%;
    margin-top: 100px;
  }
  @media ${devices.laptop} {
    width: 506px;
    margin-top: 0px;
  }

  .ant-input-affix-wrapper {
    border-radius: 10px;
    border: 1px solid var(--Slate-200, #e2e8f0);
    background: var(--white-100, #fff);
  }
`;
const Header = styled.div`
  color: var(--Background-Dark-100, #080e1a);
  font-family: Satoshi;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;
const Body = styled.div`
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const FieldItem = styled.div`
  display: flex;
  padding-bottom: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
const FieldLabel = styled.div`
  color: var(--Gray-700, #374151);
  text-align: center;
  font-family: Satoshi-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;
const StyledInput = styled(Input)`
  display: flex;
  height: 56px;
  padding: 16px 16px 16px 0;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
const StyledButton = styled(Button)<{ $isActive: 'true' | 'false' }>`
  display: flex;
  padding: 16px 24px 16px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  height: auto;

  border-radius: 20px;
  background: var(--Zinc-300, #d4d4d8);

  font-variant-numeric: lining-nums tabular-nums;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  color: var(--white-100, #fff);
  &:hover {
    color: var(--white-100, #fff) !important;
  }

  ${({ $isActive }) =>
    $isActive === 'true' &&
    `background: #0B1323;
  `}
`;
const Notice = styled.div<{ $open: 'true' | 'false' }>`
  display: ${({ $open }) => ($open === 'true' ? 'flex' : 'none')};
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const Image = styled.img`
  width: 24px;
  height: 24px;
`;
const Message = styled.div`
  color: var(--Blue-500, #374151);
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;
const BackBtn = styled.div`
  display: flex;
  width: 99px;
  height: 50px;
  padding: 10px 0px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  cursor: point;
  @media ${devices.mobileS} {
    display: flex;
  }
  @media ${devices.laptop} {
    display: none;
  }
`;
export interface WaitlistProps {
  onJoinWaitlist: (data: {
    email: string;
    name: string;
  }) => Promise<{ success?: boolean; err?: Error }>;
  onBack: () => void;
}
export const Waitlist: FC<WaitlistProps> = ({ onJoinWaitlist, onBack }) => {
  const [email, setEmail] = useState<string>('');
  const [messageEmail, setMessageEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [showMessage, setShowMessage] = useState<boolean>(false);
  return (
    <Root>
      <BackBtn onClick={onBack}>
        <BackArrowIcon /> Back
      </BackBtn>
      <Header>Join waitlist</Header>
      <Body>
        <FieldItem>
          <FieldLabel>Name</FieldLabel>
          <StyledInput
            prefix={<UserIcon />}
            size="large"
            value={name}
            placeholder="Nickname, ENS, anyone..."
            onChange={({ target: { value } }) => {
              if (showMessage) {
                setShowMessage(false);
              }
              setName(value);
            }}
          />
        </FieldItem>
        <FieldItem>
          <FieldLabel>Email Address</FieldLabel>
          <StyledInput
            prefix={<EmailIcon />}
            value={email}
            size="large"
            placeholder="Email address"
            onChange={({ target: { value } }) => {
              if (showMessage) {
                setShowMessage(false);
              }
              setEmail(value);
              setMessageEmail(value);
            }}
          />
        </FieldItem>
        <Notice $open={showMessage ? 'true' : 'false'}>
          <Image src="/success.gif" alt="persona" />
          <Message>
            <span style={{ color: '#3b82f6' }}>{messageEmail}</span> has been
            added to our waitlist. Rest assured, we'll notify you when Persona
            is ready for launch!
          </Message>
        </Notice>
      </Body>
      <StyledButton
        $isActive={name && email ? 'true' : 'false'}
        onClick={() =>
          onJoinWaitlist({ email, name }).then(({ success }) => {
            if (success) {
              setShowMessage(true);
              setEmail('');
              setName('');
            }
          })
        }
      >
        Join waitlist
      </StyledButton>
    </Root>
  );
};
