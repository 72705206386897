import { FC } from 'react';
import { styled } from 'styled-components';
import { IArticle } from '../../types';
import { LogoFullIcon } from '../../../common';

const Root = styled.div`
  border-radius: 10px;
  background: var(--LightMode-Background, #fff);
  cursor: pointer;
  max-width: 362px;
  box-shadow: 1px 1px 5px #dedede;
`;
const Header = styled.div<{ $bgImage: string }>`
  display: flex;
  padding: 64px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px 10px 0px 0px;
  background: url('${({ $bgImage }) => $bgImage}') no-repeat center center /
    cover;
  height: 180px;
  width: 100%;
  border-bottom: solid 0.5px #b8bbc0;
`;
const ArticleLabel = styled.div`
  color: var(--Gray-700, #000);
  text-align: center;
  font-family: Satoshi;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
`;
const Body = styled.div`
  height: calc(362px - 24px);
  width: 100%;

  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px 0px 10px 10px;
  justify-content: space-around;
  background: var(--LightMode-Background, #fff);
`;
const TagContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const Tag = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--Green-50, #f0fdf4);

  //typo
  color: var(--Green-800, #166534);
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
`;
const Title = styled.div`
  color: var(--Gray-700, #374151);
  font-family: Satoshi;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;
const PubDate = styled.div`
  color: var(--Gray-500, #7f8a9a);
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export interface BlogCardProps {
  article: IArticle;
  onOpen: () => void;
}
export const BlogCard: FC<BlogCardProps> = ({ article, onOpen }) => {
  return (
    <Root onClick={onOpen}>
      <Header $bgImage={article.image || '/persona-bg.svg'}>
        {/* <LogoFullIcon style={{ width: 125, height: 51 }} />
        <ArticleLabel>ARTICLE</ArticleLabel> */}
      </Header>
      <Body>
        <TagContainer>
          {article.tags?.map((tag, index) => {
            return <Tag key={`article-tag-${index}`}>{tag}</Tag>;
          })}
        </TagContainer>
        <Title dangerouslySetInnerHTML={{ __html: article.title }} />
        <PubDate>{article?.publishedDate}</PubDate>
      </Body>
    </Root>
  );
};
