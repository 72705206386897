import { FC } from 'react';
import { styled } from 'styled-components';
import {
  Description,
  DotIcon,
  EclipseIcon,
  Headline,
  HeadlineText,
  Label,
  LandingPageField,
  devices,
} from '../../../common';

const ImageContainer = styled.div`
  position: relative;
  @media ${devices.mobileS} {
    width: 100%;
    height: 400px;
  }
  @media ${devices.laptop} {
    width: 1000px;
    height: 664px;
  }
  display: flex;
  justify-content: center;
`;
const IconContainer = styled.div<{ $second: 'true' | 'false' }>`
  position: absolute;
  z-index: 0;
  ${({ $second }) =>
    $second === 'true'
      ? `
    bottom: 0px;
    left: 0px;
  `
      : `
  right: 0px;
  top: 0px;
  `}
`;
const Image = styled.div`
  display: flex;
  position: absolute;
  @media ${devices.mobileS} {
    width: 100%;
    height: 100%;
    background: url('/messenger.png') no-repeat center center/ cover;
  }
  @media ${devices.laptop} {
    width: 1000px;
    height: 664px;
    background: url('/messenger.png') no-repeat center center/ cover;
  }
`;

export interface MessengerProps {}
export const Messenger: FC<MessengerProps> = () => {
  return (
    <LandingPageField>
      <Label>
        <DotIcon /> Communication made easier
      </Label>
      <Headline>
        <HeadlineText>Web</HeadlineText>
        <HeadlineText $colored="true">3</HeadlineText>
        <HeadlineText>Messenger</HeadlineText>
      </Headline>
      <Description>
        Unify your chat experience: Get exclusive access to token-gated chats,
        share multimedia content, send tokens, and make proposals to communities
        — all in one messenger.
      </Description>
      <ImageContainer>
        <IconContainer $second="false">
          <EclipseIcon />
        </IconContainer>
        <IconContainer $second="true">
          <EclipseIcon />
        </IconContainer>
        <Image />
      </ImageContainer>
    </LandingPageField>
  );
};
