import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { appRoutes } from './app/utils';
import { SiteLayout } from './ui/common/base';
import { ApiResponse } from './ui';
import {
  selectSettings,
  setWaitlist,
  useAppDispatch,
  useAppSelector,
} from './app/state';

function App() {
  const { waitlist } = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();
  const { NODE_ENV } = process.env
  const addToWaitlist = async (
    name: string,
    email: string,
  ): Promise<ApiResponse> => {
    const isDev = NODE_ENV === "development"
    try {
      const awaitResponse = await fetch(
        `${isDev ? process.env.REACT_APP_BACK_END_URL : "/api"}/waitlist`,
        {
          method: 'POST',
          body: JSON.stringify({ name, email }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const { data, error } = await awaitResponse.json();
      if (data) return data;
      else throw error;
    } catch (err) {
      return { err: err as Error };
    }
  };
  return (
    <BrowserRouter>
      {appRoutes.map((route, index) => {
        return (
          <Routes key={`app-routes-${index}`}>
            <Route
              path={route.path}
              element={
                <SiteLayout
                  {...{
                    addToWaitlist,
                    openModal: waitlist,
                    setOpenModal(open) {
                      dispatch(setWaitlist(open));
                    },
                  }}
                >
                  <route.element />
                </SiteLayout>
              }
            />
          </Routes>
        );
      })}
    </BrowserRouter>
  );
}

export default App;
