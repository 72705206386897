import { FC } from 'react';
import { styled } from 'styled-components';
import {
  Description,
  DotIcon,
  Label,
  LandingBtn,
  LandingPageField,
  devices,
} from '../../../common';
import { Headline } from './Headline';
import { Cards } from './Cards';

const CardImage = styled.img`
  width: calc(100% - 100px);
  // height: 238px;
  @media ${devices.mobileS} {
    display: flex;
  }
  @media ${devices.laptop} {
    display: none;
  }
`;
const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${devices.mobileS} {
  }
  @media ${devices.laptop} {
    margin-top: 100px;
  }
`;
export interface HeroProps {
  onJoinWaitlist: () => void;
}
export const Hero: FC<HeroProps> = ({ onJoinWaitlist }) => {
  return (
    <LandingPageField>
      <Label>
        <DotIcon />
        Introducing persona
      </Label>
      <Headline />
      <Description>
        Turn your PFPs into unique identities. Pick a web3 name, join a guild,
        and verify with social accounts. Create unlimited profiles, all in your
        wallet.
      </Description>
      <Cards />
      <CardImage src="/card.png" alt="persona cards" />
      <BtnContainer>
        <LandingBtn style={{ width: 290 }} onClick={onJoinWaitlist}>
          Join waitlist
        </LandingBtn>
      </BtnContainer>
    </LandingPageField>
  );
};
