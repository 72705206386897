import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BlogView, IArticle, routeNames } from '../../../ui';
import { blogApi, onSub } from '../../utils';

export default function BlogArticle() {
  const { id: blogId } = useParams();
  const [article, setArticle] = useState<IArticle>();
  const navigate = useNavigate();
  const [articles, setArticles] = useState<IArticle[]>([]);
  // const [blogId, setBlogId] = useState<string>();
  const getBlogDetail = (id: string) => {
    fetch(`${blogApi}/fullArticles/${id}`)
      .then((res) => res.json())
      .then((res) => {
        setArticle(res);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch((e) => console.error({ e }));
  };
  useEffect(() => {
    if (blogId === undefined) {
      return;
    }
    getBlogDetail(blogId);
  }, [blogId]);
  useEffect(() => {
    fetch(`${blogApi}/metaData`)
      .then((res) => res.json())
      .then((res) => {
        setArticles(res);
      })
      .catch((e) => console.error({ e }));
  }, []);
  return (
    <BlogView
      {...{
        onSub,
        article,
        related: articles,
        onOpen(blogId) {
          navigate(routeNames.blog_ + blogId);
        },
      }}
    />
  );
}
