import { FC } from 'react';
import { styled } from 'styled-components';
import {
  AirStackIcon,
  AlchemyIcon,
  BaseIcon,
  DotIcon,
  EFPIcon,
  EnsIcon,
  Label,
  LandingPageField,
  XmtpIcon,
  devices,
} from '../../../common';

const IconContainer = styled.div<{
  $color?: string;
  $bg?: string;
  $name?: string;
}>`
  display: flex;
  align-items: center;
  gap: 12px;
  svg {
    rect {
      transition: fill 1s ease-out;
    }
    g rect {
      transition: fill 1s ease-out;
    }
    circle {
      transition:
        fill 1s ease-out,
        stroke 1s ease-out;
    }
    path {
      transition: fill 1s ease-out;
    }
  }
  div {
    transition: color 1s ease-out;
  }
  ${({ $name, $bg }) =>
    $name === 'efp' &&
    `
    svg circle {
      fill: ${$bg};
    }
  `}
  &:hover {
    ${({ $color }) => `
      div {
        color: ${$color};
      }
    `}
    svg {
      ${({ $bg, $name }) => {
        switch ($name) {
          case 'efp':
            return `
              circle {
                fill: url(#linear);
              }
          `;
          case 'alchemy':
            return `
              circle {
                stroke: ${$bg};
              }
              g rect {
                fill: ${$bg};
              }
          `;
          case 'ens':
            return `
              path {
                fill: ${$bg};
              }
          `;
          case 'airstack':
            return `
            path:nth-child(n+2):nth-child(-n+5) {
              fill: #FF284B; 
            }
            path:nth-child(n+6):nth-child(-n+9) {
              fill: #103D64; 
            }
            path:nth-child(n+10):nth-child(-n+13) {
              fill: #A9D6D7; 
            }
          `;
          default:
            return `
              path {
                fill: ${$bg};
              }
          `;
        }
      }}
    }
  }
`;
const IconLabel = styled.div`
  color: var(--Gray-700, #374151);
  font-family: Satoshi-Medium;
  font-style: normal;
  font-weight: 500;
  @media ${devices.mobileS} {
    font-size: 18.104px;
    line-height: 22.631px; /* 125% */
  }
  @media ${devices.laptop} {
    font-size: 32px;
    line-height: 40px; /* 125% */
  }
`;
const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 50px;
  @media ${devices.desktop} {
    padding: 20px;
  }
`;
const data = [
  { label: 'ENS', Icon: EnsIcon, color: '#9FA1FF' },
  { label: 'Alchemy', Icon: AlchemyIcon, color: '#0052FF' },
  {
    label: 'EFP',
    Icon: EFPIcon,
    fill: '#1A2828',
    color: '#FF83B7',
  },
  { label: 'airstack', Icon: AirStackIcon, color: '#ABD7D8' },
  { label: 'XMTP', Icon: XmtpIcon, color: '#6F15E0', fill: '#FC4F37' },
  { label: 'BASE', Icon: BaseIcon, color: '#0052FF' },
];
export interface PartnersProps {}
export const Partners: FC<PartnersProps> = () => {
  return (
    <LandingPageField style={{ marginBottom: 50 }}>
      <Label>
        <DotIcon /> Partners & Technologies
      </Label>
      <Body style={{ flexWrap: 'wrap', gap: 10 }}>
        {data.map((item, index) => {
          return (
            <IconContainer
              $color={item.color}
              $bg={item.fill || item.color}
              key={`partners-persona-${index}`}
              $name={item.label.toLowerCase()}
            >
              <item.Icon />
              <IconLabel>{item.label}</IconLabel>
            </IconContainer>
          );
        })}
      </Body>
    </LandingPageField>
  );
};
