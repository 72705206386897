import { LandingPage } from '../../../ui';
import { setWaitlist, useAppDispatch } from '../../state';

export default function HomePage() {
  const dispatch = useAppDispatch();

  return (
    <LandingPage
      {...{
        onJoinWaitlist() {
          dispatch(setWaitlist(true));
        },
      }}
    />
  );
}
