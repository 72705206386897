import { FC, useState } from 'react';
import { css, styled } from 'styled-components';
import {
  BareXIcon,
  FarcasterBareIcon,
  FarcasterIcon,
  LogoFullIcon,
  LoveIcon,
  MenuIcon,
  MirrorBareIcon,
  MirrorIcon,
  XIcon,
} from '../../../svg';
import { Drawer, Layout, List } from 'antd';
import {
  LandingBtn,
  Logo,
  LogoText,
  animation,
  devices,
} from '../../../styles';
import { personaInfoDetail } from '../../../appData';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from '../../../../utils';

const StyledHeader = styled(Layout.Header)`
  width: 100dvw;
  height: 100px;
  flex-shrink: 0;
  backdrop-filter: blur(15px);
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  @media ${devices.mobileS} {
    padding: 0 24px;
    justify-content: space-between;
  }
  @media ${devices.laptop} {
    padding: 0 50px;
  }
  z-index: 1000;

  position: fixed;
`;
const LogoContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 16px;
`;
const MenuSwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${devices.mobileS} {
    display: none;
  }
  @media ${devices.laptop} {
    width: 33.3%;
    display: flex;
  }
`;
const MenuSwitch = styled.div`
  display: flex;
  padding: 8px 34px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border-radius: 100px;
  border-top: 0.5px solid #374151;
  border-bottom: 0.5px solid #374151;
  height: 50px;
`;
const MediaSection = styled.div`
  align-items: center;
  gap: 10px;
  @media ${devices.mobileS} {
    display: none;
    width: 33.3%;
  }
  @media ${devices.laptop} {
    display: flex;
  }
`;
const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  @media ${devices.laptop} {
    display: none;
  }
`;
const MediaContainer = styled.div`
  display: flex;
  // width: 247px;
  height: 56px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 0.5px solid rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.5);
  svg {
    cursor: pointer;
  }
`;
const DrawerView = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  svg {
    width: 70px;
  }
`;
const IconContainer = styled.div<{
  $color: string;
  $animate: 'rotate' | 'flight';
}>`
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;

  border-radius: 99.428px;
  border: 0.3px solid #f2f2f2;
  background: ${({ $color }) => $color};
  &:hover {
    svg {
      animation: ${({ $animate }) =>
        $animate === 'flight'
          ? css`
              ${animation.flight} 2s ease-in-out forwards
            `
          : css`
              ${animation.clockwise360} 1s linear
            `};
    }
  }
`;
const Connect = styled.div`
  color: var(--Gray-500, #6b7280);
  text-align: center;
  font-family: Satoshi;
  font-size: 16px;
  font-style: medium;
  font-weight: 500;
  line-height: 28px; /* 233.333% */

  display: flex;
  align-items: center;
  gap: 10px;
`;
export const StyledLogoFullIcon = styled(LogoFullIcon)`
  height: 50px;
  @media ${devices.mobileS} {
    width: 150px;
  }
  @media ${devices.laptop} {
    width: 150px;
  }
`;
const Tab = styled.div<{ $active: 'true' | 'false' }>`
  color: var(--Gray-700, #374151);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ $active }) => ($active === 'true' ? 700 : 400)};
  line-height: 24px; /* 150% */
  cursor: pointer;
`;
export const AppLogo = () => (
  <LogoContainer>
    <Logo />
    <LogoText>persona</LogoText>
  </LogoContainer>
);
const DMenuContainer = styled.div`
  display: flex;
  width: 247px;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex: 1 1;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.5);
`;
const DMenuItem = styled.div<{ $active: 'true' | 'false' }>`
  color: var(--Gray-700, #374151);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
  font-weight: ${({ $active }) => ($active === 'true' ? 700 : 400)};
  cursor: pointer;
`;

export interface HeaderProps {
  onJoinWaitlist: () => void;
}
export const Header: FC<HeaderProps> = ({ onJoinWaitlist }) => {
  const { pathname } = useLocation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <StyledHeader>
      <Link
        to={routeNames.home}
        style={{ width: '33.3%', display: 'flex', alignItems: 'center' }}
      >
        <StyledLogoFullIcon />
      </Link>
      <MenuSwitchContainer>
        <MenuSwitch>
          <Tab
            $active={pathname === routeNames.home ? 'true' : 'false'}
            onClick={() => navigate(routeNames.home)}
          >
            Home
          </Tab>
          <Tab
            $active={pathname.includes('/blog') ? 'true' : 'false'}
            onClick={() => navigate(routeNames.blogs)}
          >
            Blog
          </Tab>
        </MenuSwitch>
      </MenuSwitchContainer>
      <MediaSection>
        <MediaContainer>
          <IconContainer
            $color="#000"
            onClick={() => window.open(personaInfoDetail.twitter)}
            $animate="rotate"
          >
            <BareXIcon />
          </IconContainer>
          <IconContainer
            $animate="rotate"
            $color="#007AFF"
            onClick={() => window.open(personaInfoDetail.mirror)}
          >
            <FarcasterBareIcon />
          </IconContainer>
          <IconContainer
            $animate="rotate"
            $color="#855DCD"
            onClick={() => window.open(personaInfoDetail.farcaster)}
          >
            <MirrorBareIcon />
          </IconContainer>
        </MediaContainer>
        <LandingBtn onClick={onJoinWaitlist}>Join waitlist</LandingBtn>
      </MediaSection>
      <MenuContainer
        style={{ marginBottom: 10 }}
        onClick={() => setOpenModal(true)}
      >
        <MenuIcon />
      </MenuContainer>
      <Drawer open={openModal} onClose={() => setOpenModal(false)}>
        <DrawerView>
          <DMenuContainer>
            <DMenuItem
              $active={pathname === routeNames.home ? 'true' : 'false'}
              onClick={() => navigate(routeNames.home)}
            >
              Home
            </DMenuItem>
            <DMenuItem
              $active={pathname.includes('/blog') ? 'true' : 'false'}
              onClick={() => navigate(routeNames.blogs)}
            >
              Blog
            </DMenuItem>
          </DMenuContainer>
          <div style={{ display: 'flex', flexGrow: 1 }} />
          <XIcon
            onClick={() => window.open(personaInfoDetail.twitter)}
            style={{ width: 36, height: 36 }}
          />
          <MirrorIcon
            onClick={() => window.open(personaInfoDetail.mirror)}
            style={{ width: 36, height: 36 }}
          />
          <FarcasterIcon
            onClick={() => window.open(personaInfoDetail.farcaster)}
            style={{ width: 36, height: 36 }}
          />
          <Connect>
            Connect with us <LoveIcon style={{ marginLeft: -20 }} />
          </Connect>
          <LandingBtn
            onClick={() => {
              onJoinWaitlist();
              setOpenModal(false);
            }}
          >
            Join waitlist
          </LandingBtn>
        </DrawerView>
      </Drawer>
    </StyledHeader>
  );
};
