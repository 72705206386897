import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { IENSRecord, IGuildKey } from '../../../types';
import {
  SocialMedia,
  ENSName,
  PersonaBase,
  PersonaBaseProps,
} from '../../../../common/base';
import { VerifiedIcon } from '../../../../common';

export const SubHeader = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #374151;
`;
const Description = styled(SubHeader)`
  line-height: 0px;
  font-size: 16px;
  padding-bottom: 10px;
  // color: #242d3f;
`;

export interface PersonCardProps {
  ensRecord: IENSRecord;
  onClick?: PersonaBaseProps['onClick'];
  size?: PersonaBaseProps['size'];
}
export const PersonCard: React.FC<PersonCardProps> = ({
  ensRecord,
  onClick,
  size,
}) => {
  const { texts, ens, score } = ensRecord;
  const [open, setOpen] = useState<boolean>(false);
  const bodyRef = useRef(null);
  const {
    'com.github': github,
    'org.telegram': telegram,
    'com.discord': discord,
    'com.twitter': twitter,
  } = texts || {};
  useEffect(() => {
    if (open) {
      if (bodyRef?.current)
        (bodyRef.current as HTMLDivElement).style.display = 'flex';
      setTimeout(() => {}, 500);
    } else {
      setTimeout(() => {
        if (bodyRef?.current)
          (bodyRef.current as HTMLDivElement).style.display = 'none';
      }, 650);
    }
  }, [open]);
  return (
    <PersonaBase
      size={size}
      onClick={onClick}
      score={score}
      open={open}
      setOpen={setOpen}
      avatar={texts?.avatar || '/assets/logo.png'}
      guild={(texts?.['art.persona'] as IGuildKey) || 'Guild'}
      footerHeader={
        <ENSName open={open} size={size}>
          {ens} {ens ? <VerifiedIcon /> : ''}
        </ENSName>
      }
      footerBody={
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Description>
              {texts?.description || 'neo in the matrix'}
            </Description>
            <Description>{texts?.email || 'persona@mail.com'}</Description>
          </div>
          <div style={{ marginTop: 80 }} />
          <SocialMedia centered {...{ twitter, telegram, discord, github }} />
        </div>
      }
    />
  );
};
