import { FC, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import {
  DotIcon,
  Headline,
  HeadlineText,
  Label,
  LandingBtn,
  LandingPageField,
  devices,
} from '../../../common';
const data = [
  {
    name: 'art',
    label: 'Art',
    description:
      'From PFPs to Modern Art, mint it here. As a collection, or One-for-One.',
    icon: '/media.gif',
    image: '/art.png',
  },
  {
    name: 'article',
    label: 'Writing',
    description:
      'Stories, Articles, Blogs, Whitepapers - whatever form of writing, mint them into collectibles.',
    icon: '/pen.gif',
    image: '/nft.png',
  },
  {
    name: 'video',
    label: 'Film',
    icon: '/video.gif',
    image: '/film.png',
    description:
      'Animated Film, Music Videos, or Footage from your phone. Turn them into collectibles and connect with your audience directly.',
  },
];
const Body = styled.div`
  display: flex;
  @media ${devices.mobileS} {
    flex-direction: column;
  }
  @media ${devices.laptop} {
    flex-direction: row;
  }
  align-items: center;
  gap: 20px;
  width: 100%;
  overflow: hidden;
  .visible {
    display: block;
  }
  .active {
    width: 49%;
    @media ${devices.mobileS} {
      width: 100%;
    }
    @media ${devices.laptop} {
      width: 49%;
    }
    border-radius: 24px;
    border: 1px solid rgba(55, 65, 81, 0);
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    box-shadow: 0px 1px 1px lightgray;
    > [src$='.png'] {
      display: block;
    }
  }
  .inactive {
    width: 24%;
    border-radius: 24px;
    background: var(--Gray-50, #f9fafb);
    justify-content: space-around;
    @media ${devices.mobileS} {
      width: 100%;
    }
    @media ${devices.laptop} {
      width: 25%;
    }
    > [src$='.gif'] {
      display: block;
    }
  }
`;
const Box = styled.div<{ $expandFrom: 'left' | 'right' | 'center' }>`
  height: 368px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 1px solid rgba(55, 65, 81, 0);
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  gap: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  transition: width 0.3s ease-in-out;
  transform-origin: ${({ $expandFrom }) => $expandFrom};

  > img {
    display: none;
  }
`;
const Title = styled.div`
  color: var(--Gray-700, #374151);
  font-family: Satoshi;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
`;
const Description = styled.div`
  color: var(--Gray-500, #6b7280);
  font-family: Satoshi-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-align: center;
`;
const Image = styled.img`
  width: 300px;
  height: 216px;
`;
const GifImage = styled.img`
  width: 64px;
  height: 64px;
  flex-shrink: 0;
`;
export interface CreativityProps {
  onJoinWaitlist: () => void;
}
export const Creativity: FC<CreativityProps> = ({ onJoinWaitlist }) => {
  const refs = [
    useRef<HTMLImageElement>(null),
    useRef<HTMLImageElement>(null),
    useRef<HTMLImageElement>(null),
  ];
  const activate = (ref: React.RefObject<HTMLImageElement>, align: string) => {
    const target = ref?.current;
    if (target) {
      target.classList.replace('inactive', 'active');
      target.querySelectorAll('div').forEach((div) => {
        div.style.textAlign = align;
      });
      target.style.alignItems = align;
    }
  };
  const inactivate = (
    refs: React.RefObject<HTMLImageElement>[],
    aligns: string[],
  ) => {
    refs.forEach((ref, index) => {
      const target = ref?.current;
      if (target) {
        target.classList.replace('active', 'inactive');
        target.style.alignItems = aligns[index];
        target.querySelectorAll('div').forEach((div) => {
          div.style.textAlign = aligns[index].split('-')[1];
        });
      }
    });
  };
  useEffect(() => {
    const [firstRef, secondRef, thirdRef] = refs;
    activate(secondRef, 'center');
    inactivate([firstRef, thirdRef], ['flex-start', 'flex-start']);
    firstRef.current?.addEventListener('mouseenter', (e) => {
      inactivate([secondRef, thirdRef], ['flex-start', 'flex-start']);
      activate(firstRef, 'center');
    });
    secondRef.current?.addEventListener('mouseenter', (e) => {
      activate(secondRef, 'center');
      inactivate([firstRef, thirdRef], ['flex-start', 'flex-start']);
    });
    thirdRef.current?.addEventListener('mouseenter', (e) => {
      activate(thirdRef, 'center');
      inactivate([secondRef, firstRef], ['flex-start', 'flex-start']);
    });
  }, []);
  return (
    <LandingPageField>
      <Label>
        <DotIcon /> Craft unique NFTs
      </Label>
      <Headline>
        <HeadlineText>Own</HeadlineText>
        <HeadlineText $colored="true">Your</HeadlineText>
        <HeadlineText>Creativity</HeadlineText>
      </Headline>
      <Description>
        Be inspired to create with persona: Transform writing, art, and film
        into collectibles let the world buy your work.
      </Description>
      <Body>
        {data.map((item, index) => {
          return (
            <Box
              key={`items-nft-${index}`}
              className={index === 1 ? 'active' : 'inactive'}
              ref={refs[index]}
              $expandFrom={
                index !== 1 ? (index === 0 ? 'left' : 'right') : 'center'
              }
            >
              <Title>{item.label}</Title>
              <Description>{item.description}</Description>
              <Image src={item.image} alt={`persona-image-${item}`} />
              <GifImage
                className="icon"
                src={item.icon}
                alt={`persona-icon-${item}`}
              />
            </Box>
          );
        })}
      </Body>
      <LandingBtn
        style={{ width: 286, marginTop: 50 }}
        onClick={onJoinWaitlist}
      >
        Join waitlist
      </LandingBtn>
    </LandingPageField>
  );
};
