import { FC, useEffect, useRef, useState } from 'react';
import {
  Description,
  DotIcon,
  Headline,
  HeadlineText,
  Label,
  LandingPageField,
} from '../../../common';
import { Profile } from './Profile';

export interface ReputationProps {}
export const Reputation: FC<ReputationProps> = ({}) => {
  const [followers, setFollowers] = useState<number>(68);
  const [followings, setFollowings] = useState<number>(15);
  const [repScore, setRepScore] = useState<number>(48);
  const repRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let interval: NodeJS.Timer;
    const handler = () => {
      let _followers = followers;
      let _followings = followings;
      let _repScore = repScore;
      if (interval) clearInterval(interval);
      interval = setInterval(() => {
        if (_followers === 99) _followers = 0;
        setFollowers(++_followers);
        if (_followings === 99) _followings = 0;
        setFollowings(++_followings);
        if (_repScore === 99) _repScore = 0;
        setRepScore(++_repScore);
      }, 2000);
    };
    repRef.current?.addEventListener('mouseenter', handler);
    repRef.current?.addEventListener('mouseleave', () => {
      clearInterval(interval);
    });
    return () => clearInterval(interval);
  }, [followers, followings, repScore]);
  return (
    <LandingPageField ref={repRef}>
      <Label>
        <DotIcon /> Onchain Reputation
      </Label>
      <Headline>
        <HeadlineText>Build</HeadlineText>
        <HeadlineText $colored="true">Your</HeadlineText>
        <HeadlineText>Reputation</HeadlineText>
      </Headline>
      <Description>
        Attain influence and clout with your personas: Connect your persona to
        any web3 app to generate an onchain score. Earn points and credentials
        in your guild. Make connections and gain followers.
      </Description>
      <Profile {...{ followers, followings, repScore }} />
    </LandingPageField>
  );
};
