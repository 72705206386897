import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
interface ISettingState {
  waitlist: boolean;
}
const initialState: ISettingState = {
  waitlist: false,
};

const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setWaitlist: (state, action: PayloadAction<boolean>) => {
      state.waitlist = action.payload;
    },
  },
});

export const { setWaitlist } = settingSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectSettings = (state: RootState): ISettingState =>
  state.settings;

export default settingSlice.reducer;
