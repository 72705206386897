import { FC } from 'react';
import { styled } from 'styled-components';
import { VerifiedIcon, devices } from '../../../../common';
import { RepScore, Spinner } from '../../../../common/base';

const Root = styled.div`
  min-height: 500px;
  width: 100%;
  background: url('/pfp-bg.png') no-repeat center center / cover;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: start;
`;
const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const Avatar = styled.img`
  display: flex;
  width: 169px;
  height: 175px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const Name = styled.div`
  color: var(--Gray-700, #374151);
  font-family: Satoshi;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  letter-spacing: -0.32px;
`;
const Description = styled.div`
  color: var(--Gray-500, #6b7280);
  text-align: center;
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;
const Matrix = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;
  @media ${devices.mobileS} {
    flex-direction: column;
    gap: 20px;
  }
  @media ${devices.laptop} {
    flex-direction: row;
    gap: 12px;
  }
`;

const GuildContainer = styled.div`
  display: flex;
  align-items: center;
`;
const FollowContainer = styled(Matrix)`
  margin-top: 0;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Guild = styled.div<{ $isguild?: 'true' | 'false' }>`
  display: flex;
  height: 40px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 24px;
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  ${({ $isguild }) =>
    $isguild === 'true'
      ? `
      color: var(--white-100, #fff);
      background: #ca4a02;
      box-shadow:
    0px 12px 30px -10px rgba(201, 74, 2, 0.4),
    0px 3px 10px 0px rgba(201, 74, 2, 0.08) inset;
    display: flex;
    `
      : `
      color: var(--Gray-700, #374151);
      background: #fff;
border: 1px solid var(--Slate-200, #E2E8F0);
background: var(--white-100, #FFF);
    `};
`;
const VertLine = styled.div`
  width: 1px;
  height: 23px;
  background: #e2e8f0;
`;
const Compact = styled.div`
  display: flex;
  align-items: center;
`;
export interface ProfileProps {
  followers: number;
  followings: number;
  repScore: number;
}
export const Profile: FC<ProfileProps> = ({
  followers,
  followings,
  repScore,
}) => {
  return (
    <Root>
      <Content>
        <Avatar src="/avatar.png" alt="persona-avatar" />
        <InfoDiv>
          <Name>
            adebimpe.xyz <VerifiedIcon />
          </Name>
          <Description>
            Blockchain and cryptography enthusiast aimed at learning
          </Description>
        </InfoDiv>
        <Matrix>
          <GuildContainer>
            <Guild $isguild="true">Engineer</Guild>
            <VertLine />
          </GuildContainer>
          <FollowContainer>
            <Guild>
              <Compact>
                {String(followers)
                  .split('')
                  .map((f, i) => {
                    return <Spinner key={`follower-count-${i}`} node={f} />;
                  })}
              </Compact>
              Followers
            </Guild>
            <VertLine />
            <Guild>
              <Compact>
                {String(followings)
                  .split('')
                  .map((f, i) => {
                    return <Spinner key={`follower-count-${i}`} node={f} />;
                  })}
              </Compact>
              Following
            </Guild>
            <VertLine />
            <Guild>
              Reputation score: <RepScore score={repScore} />
            </Guild>
          </FollowContainer>
        </Matrix>
      </Content>
    </Root>
  );
};
