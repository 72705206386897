import { FC, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import { devices } from '../../../../common';
import { PersonCard } from './PersonCard';
import { people } from './data';
import { footerHeight } from '../../../../common/base';

const calcPercent = (value: number, percent: number = 100) => {
  return (value * percent) / 100;
};
const _firstInitTransform = (percent?: number) =>
  `rotate(-5deg) 
  translateX(-${percent ? 350 - calcPercent(350, percent + 5) : 350}px) 
  translateY(${percent ? 25 - calcPercent(25, percent) : 25}px)`;
const _secondInitTransform = (percent?: number) =>
  `rotate(5deg) 
  translateX(${percent ? 350 - calcPercent(350, percent) : 350}px) 
  translateY(${percent ? 25 - calcPercent(25, percent) : 25}px)`;

const firstInitTransform = 'rotate(-5deg) translateX(-350px) translateY(25px)';
const secondInitTransform = 'rotate(5deg) translateX(350px) translateY(25px)';
const Root = styled.div`
  display: flex;
  position: relative;
  width: 440px;
  @media ${devices.mobileS} {
    display: none;
  }
  @media ${devices.laptop} {
    display: flex;
    // min-height: calc(411px + 40px);
  }
  @media ${devices.laptopL} {
    width: 480px;
    display: flex;
    // min-height: calc(511px + 40px);
  }

  min-height: calc(511px + 40px);
  justify-content: center;
  padding: 0 50px;
  .tilt-left {
    transform: rotate(-15deg) translateX(-301px) translateY(-23px);
    @media ${devices.laptopL} {
      transform: rotate(-15deg) translateX(-390px) translateY(-50px);
    }
  }
  .tilt-right {
    transform: rotate(15deg) translateX(336px) translateY(-23px);
    @media ${devices.laptopL} {
      transform: rotate(15deg) translateX(425px) translateY(-50px);
    }
  }
`;
const Card = styled.div<{ $image: string }>`
  background: url('${({ $image }) => $image}') no-repeat center center / contain;
  margin: 20px;
  transition: transform 0.5s ease-in-out;
  position: absolute;
  z-index: 100;
  overflow: visible;
  font-family: Satoshi;

  // padding: 16px;
  // background: #fff;
`;
const frontHeight = 452 + footerHeight;
const cardWidth = 408;
const SecondCard = styled(Card)`
  width: calc(448px - ${calcPercent(448, 10)}px);
  height: calc(${frontHeight}px - ${calcPercent(520, 10)}px);
  @media ${devices.laptopL} {
    width: 448px;
    height: ${frontHeight}px;
  }
`;
const FirstCard = styled(Card)`
  z-index: 0;
  width: calc(408px - ${calcPercent(408, 10)}px);
  height: calc(${frontHeight}px - ${calcPercent(frontHeight, 16)}px);
  transform: ${_firstInitTransform(40)};
  left: -50px;
  @media ${devices.laptopL} {
    left: 0px;
    transform: ${firstInitTransform};
    width: 408px;
    height: calc(${frontHeight}px - ${calcPercent(frontHeight, 10)}px);
  }
`;
const ThirdCard = styled(Card)`
  z-index: 0;
  width: calc(408px - ${calcPercent(408, 10)}px);
  height: calc(${frontHeight}px - ${calcPercent(frontHeight, 16)}px);
  transform: ${_secondInitTransform(40)};
  left: 50px;
  @media ${devices.laptopL} {
    left: 0px;
    transform: ${secondInitTransform};
    width: 408px;
    height: calc(${frontHeight}px - ${calcPercent(frontHeight, 10)}px);
  }
`;
export interface CardsProps {}
export const Cards: FC<CardsProps> = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cardRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  useEffect(() => {
    const handleMouseEnter = () => {
      cardRefs[0]?.current?.classList.add('tilt-left');
      cardRefs[2]?.current?.classList.add('tilt-right');
    };

    const handleMouseLeave = () => {
      cardRefs[0]?.current?.classList.remove('tilt-left');
      cardRefs[2]?.current?.classList.remove('tilt-right');
    };

    containerRef.current?.addEventListener('mouseenter', handleMouseEnter);
    containerRef.current?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      containerRef.current?.removeEventListener('mouseenter', handleMouseEnter);
      containerRef.current?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);
  return (
    <Root ref={containerRef}>
      <FirstCard $image="" ref={cardRefs[0]}>
        <PersonCard ensRecord={people[1]} size="large" />
      </FirstCard>
      <SecondCard $image="" ref={cardRefs[1]}>
        <PersonCard ensRecord={people[0]} size="large" />
      </SecondCard>
      <ThirdCard $image="/persona2.png" ref={cardRefs[2]}>
        <PersonCard ensRecord={people[2]} onClick={() => {}} size="large" />
      </ThirdCard>
    </Root>
  );
};
